.alert-banner {
  --background: var(--c-muted-cream);
  --text: var(--c-text-primary);
  --c-button-hover-background: var(--c-warm-white);
  --c-button-hover-text: var(--c-text-primary);

  border-radius: 1rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--c-muted-cream);
  background-color: var(--background);
  color: var(--c-text-primary);
  color: var(--text);

  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "image text close";
  align-items: center;
  text-align: left;
}

.alert-banner .image {
    grid-area: image;
    display: none;
  }

.alert-banner .text {
    grid-area: text;
  }

.alert-banner .icon-button {
    grid-area: close;
    color: inherit;
  }

@media (min-width: 30rem) {

  .alert-banner {
    padding: 1rem 1.5rem;
}

    .alert-banner .image {
      display: block;
      width: 4rem;
    }

      .alert-banner .image > * {
        width: 100%;
        height: auto;
      }
  }

.alert-banner.success {
    --background: var(--c-green-lightest);
    --c-button-hover-background: var(--c-green-light);
  }

.alert-banner.error {
    --background: var(--c-red-lightest);
    --c-button-hover-background: var(--c-red-light);
  }
