button {
  cursor: pointer;
}

.button,
button:not(.tag):not(.toggle-option):not(.menu-trigger) {
  background: var(--c-button-background);
  border: none;
  border-radius: var(--s7);
  color: var(--c-button-text);
  display: inline-block;
  font-family: var(--font-serif);
  font-size: max(1.125rem, min(3.25vw, 1.625rem));
  padding: max(1rem, min(2.5vw, 1.25rem)) max(1.5rem, min(4vw, 2rem));
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: all var(--transition-duration-extra-fast) ease-in-out;
}

.button:hover,
  button:not(.tag):not(.toggle-option):not(.menu-trigger):hover,
  .button:focus,
  button:not(.tag):not(.toggle-option):not(.menu-trigger):focus {
    background: var(--c-button-hover-background);
    color: var(--c-button-hover-text);
  }

.button[disabled], button[disabled]:not(.tag):not(.toggle-option):not(.menu-trigger) {
    pointer-events: none;
    cursor: not-allowed;
    background: var(--c-grey-warm);
    color: var(--c-grey-cool-dark);
  }

.button.button-medium, button.button-medium:not(.tag):not(.toggle-option):not(.menu-trigger) {
    font-size: max(1.125rem, min(2.5vw, 1.25rem));
    padding: max(0.75rem, min(2.25vw, 1.125rem)) max(1.25rem, min(3.5vw, 1.75rem));
  }

.icon-button:not(.tag):not(.toggle-option):not(.menu-trigger) {
  background: transparent;
  border: none;
  color: inherit;
  padding: var(--s-3);
  border-radius: var(--s-4);
  transition: all var(--transition-duration-extra-fast) ease-in-out;
}

.icon-button:not(.tag):not(.toggle-option):not(.menu-trigger):hover,
  .icon-button:not(.tag):not(.toggle-option):not(.menu-trigger):focus {
    background: var(--c-button-hover-background);
    color: var(--c-button-hover-text);
  }

.icon-button:not(.tag):not(.toggle-option):not(.menu-trigger) svg {
    width: 1.25rem;
    height: 1.25rem;
    color: inherit;
  }
