.project-grid {
  --gap: var(--s2);

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--s2);
  grid-gap: var(--gap);
  gap: var(--s2);
  gap: var(--gap);
}
@media (max-width: 41.99rem) {
    .project-grid.zebra-highlight {
      --space: 0 !important;
    }
    .project-grid.zebra-highlight {
      --gap: 0;
    }

      .project-grid.zebra-highlight .project-card-featured:nth-of-type(even) {
        background-color: var(--c-hard-black);
        margin: 0 calc(var(--s0) * -1);
        padding-left: var(--s0);
        padding-right: var(--s0);
      }

      .project-grid.zebra-highlight .project-testimonial {
        margin-top: var(--s3);
      }
      .project-grid:not(.zebra-highlight) .project-card-featured {
        background-color: var(--c-hard-black);
        margin: 0 calc(var(--s0) * -1);
        padding-left: var(--s0);
        padding-right: var(--s0);
      }
  }
.project-grid .project-card-featured,
  .project-grid .project-testimonial {
    grid-column: 1/-1;
  }
@media (min-width: 42rem) {
  .project-grid {
    grid-template-columns: 1fr 1fr;
    row-gap: var(--s5);
}
  }
@media (min-width: 60rem) {
  .project-grid {
    grid-template-columns: 1fr 1fr 1fr;
}
  }
