.with-overline {
  display: flex;
  flex-direction: column;
}
.with-overline [class*="overline:"] {
    order: -1;
    margin-bottom: var(--s1);
  }
.with-overline [class*="overline:"]:nth-child(2) {
      margin-top: 0;
    }
