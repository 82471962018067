.extended-team {
  display: grid;
  grid-gap: var(--s1);
  gap: var(--s1);
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}
.extended-team .partner {
    background-color: var(--c-surface-light);
    padding: var(--s2);
    border-radius: var(--s-3);
  }
.extended-team .partner .services {
      --gap: 0;

      color: var(--c-text-secondary);
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1;
      margin-left: calc(var(--s-2) * -1);
      margin-right: calc(var(--s-2) * -1);
    }
.extended-team .partner .services li {
        padding: 0 var(--s-2);
      }
.extended-team .partner .services li:not(:first-child) {
          border-left: 1px solid var(--c-border-secondary);
        }
.extended-team .partner .logo {
      width: 10rem;
      height: 5.625rem;
      margin-bottom: var(--s0);
    }
.extended-team .partner .logo img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
           object-fit: contain;
        -o-object-position: left;
           object-position: left;
      }
