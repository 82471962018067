/* A modification of the Tippy.js light theme */

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  color: var(--c-text);
  box-shadow: 0 var(--s-5) var(--s0) var(--c-shadow),
    0 4px 80px -8px var(--c-shadow);
  background-color: var(--c-surface-light);
  border-radius: var(--s-1);
  white-space: normal;
  outline: 0;
  transition-property: visibility, opacity, -webkit-transform;
  transition-property: transform, visibility, opacity;
  transition-property: transform, visibility, opacity, -webkit-transform;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: calc((var(--s0) - 1px) * -1);
  left: 0;
  border-width: var(--s0) var(--s0) 0;
  border-top-color: currentColor;
  border-top-color: initial;
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: calc((var(--s0) - 1px) * -1);
  left: 0;
  border-width: 0 var(--s0) var(--s0);
  border-bottom-color: currentColor;
  border-bottom-color: initial;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: var(--s0) 0 var(--s0) var(--s0);
  border-left-color: currentColor;
  border-left-color: initial;
  right: calc((var(--s0) - 1px) * -1);
  -webkit-transform-origin: center left;
          transform-origin: center left;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: calc((var(--s0) - 1px) * -1);
  border-width: var(--s0) var(--s0) var(--s0) 0;
  border-right-color: currentColor;
  border-right-color: initial;
  -webkit-transform-origin: center right;
          transform-origin: center right;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: var(--s0);
  height: var(--s0);
  color: var(--c-surface-light);
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: var(--s-4);
  z-index: 1;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-top-color: var(--c-surface-light);
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: var(--c-surface-light);
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-left-color: var(--c-surface-light);
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-right-color: var(--c-surface-light);
}

.tippy-box > .tippy-backdrop {
  background-color: var(--c-surface-light);
}

.tippy-box > .tippy-svg-arrow {
  fill: var(--c-surface-light);
}
