.hero {
  display: grid;
  grid-gap: var(--s3);
  gap: var(--s3);
}

.hero .image {
    max-width: var(--illustration-size);
    aspect-ratio: 1/1;
    width: 100%;
    margin: 0 auto;
    grid-row: 1;
  }

.hero .image img,
    .hero .image svg {
      width: 100%;
    }

.hero .text {
    grid-row: 2;
  }

@media (min-width: 66rem) {

  .hero {
    grid-template-columns: 1fr min(var(--illustration-size), 40%);
    align-items: center;
    padding-left: var(--s5);
    padding-right: var(--s5);
}

    .hero .text {
      grid-row: 1;
    }
  }
