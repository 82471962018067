
  .js .before-after .images {
    display: grid;
  }
.js .before-after .images > * {
      grid-column: 1;
      grid-row: 1;
      opacity: 0;
      transition: opacity var(--transition-duration-fast) ease-in-out;
    }
.js .before-after .images > * img {
        border-radius: var(--s2);
      }
.js .before-after .images > *.visible {
        opacity: 1;
      }
