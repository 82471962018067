.cookie-banner {
  position: fixed;
  background: var(--c-muted-cream);
  color: var(--c-soft-black);
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  justify-items: center;
  left: 50%;
  bottom: 1.5rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: pop-in 2s ease-out;
          animation: pop-in 2s ease-out;
  border-radius: 1rem;
  padding: 1rem 2rem;
  max-width: var(--measure-large);
  width: calc(100vw - 3rem);
  box-shadow: 0 0 0.5rem var(--c-shadow);
  z-index: 10;
}

.cookie-banner .h6 {
    font-size: max(1rem, min(2.5vw, 1.125rem));
  }

@media (min-width: 42rem) {

.cookie-banner {
    grid-template-columns: 1fr auto;
    gap: 3rem;
    justify-items: initial;
    align-items: center;
}
  }

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    scale: 0.5;
  }
  90% {
    opacity: 0;
    scale: 0.5;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    scale: 0.5;
  }
  90% {
    opacity: 0;
    scale: 0.5;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
