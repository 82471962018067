.project-nav {
  --arrow-size: max(1.5rem, min(6vw, 3rem));
  --arrow-padding: calc(var(--arrow-size) + 1rem);

  display: grid;
}

.project-nav .previous > *, .project-nav .next > * {
      display: block;
    }

.project-nav .previous a, .project-nav .next a {
      color: inherit;
      text-wrap: balance;
      background-repeat: no-repeat;
      background-size: var(--arrow-size);
    }

.project-nav .next {
    grid-column: 2;
    text-align: right;
  }

.project-nav .next > * {
      padding-right: var(--arrow-padding);
    }

.project-nav .next a {
      background-image: url(/assets/images/icons/caret-right.svg);
      background-position: 100% 0.1em;
    }

.project-nav .previous {
    grid-column: 1;
  }

.project-nav .previous > * {
      padding-left: var(--arrow-padding);
    }

.project-nav .previous a {
      background-image: url(/assets/images/icons/caret-left.svg);
      background-position: 0 0.1em;
    }

@media (max-width: 41.98rem) {

  .project-nav {
    grid-template-columns: auto 1fr;
    gap: var(--s1);
}

    .project-nav .previous {
      display: flex;
      flex-direction: column;
    }

      .project-nav .previous .overline\:large,
      .project-nav .previous a span {
        display: block;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
      }

      .project-nav .previous a span {
        height: 100%;
      }
  }

@media (min-width: 66rem) {

  .project-nav {
    grid-template-columns: 1fr 1fr;
    gap: var(--s6);
}
  }
