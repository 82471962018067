.cluster,
.cluster-flush,
.cluster-tight,
.cluster-comfy,
.cluster-loose,
.cluster-center,
.cluster-spread,
.cluster-end {
  --gap: var(--s0);
  --distribution: flex-start;
  --alignment: center;
  --wrap: wrap;

  display: flex;
  flex-wrap: wrap;
  flex-wrap: var(--wrap);
  gap: var(--s0);
  gap: var(--gap);
  align-items: center;
  align-items: var(--alignment);
  justify-content: flex-start;
  justify-content: var(--distribution);
}

.cluster > *, .cluster-flush > *, .cluster-tight > *, .cluster-comfy > *, .cluster-loose > *, .cluster-center > *, .cluster-spread > *, .cluster-end > * {
    width: auto;
  }

.cluster > *.flex-grow\:1, .cluster-flush > *.flex-grow\:1, .cluster-tight > *.flex-grow\:1, .cluster-comfy > *.flex-grow\:1, .cluster-loose > *.flex-grow\:1, .cluster-center > *.flex-grow\:1, .cluster-spread > *.flex-grow\:1, .cluster-end > *.flex-grow\:1 {
      flex-grow: 1;
    }

.cluster > *.flex-grow\:0, .cluster-flush > *.flex-grow\:0, .cluster-tight > *.flex-grow\:0, .cluster-comfy > *.flex-grow\:0, .cluster-loose > *.flex-grow\:0, .cluster-center > *.flex-grow\:0, .cluster-spread > *.flex-grow\:0, .cluster-end > *.flex-grow\:0 {
      flex-grow: 0;
    }

.cluster > [class*="cluster"], .cluster-flush > [class*="cluster"], .cluster-tight > [class*="cluster"], .cluster-comfy > [class*="cluster"], .cluster-loose > [class*="cluster"], .cluster-center > [class*="cluster"], .cluster-spread > [class*="cluster"], .cluster-end > [class*="cluster"] {
    display: flex;
  }

.cluster-flush {
  --gap: var(--s-5);
}

.cluster-tight {
  --gap: var(--s-3);
}

.cluster-comfy {
  --gap: var(--s-2);
}

.cluster-loose {
  --gap: var(--s2);
}

.cluster-center {
  --distribution: center;
}

.cluster-spread {
  --distribution: space-between;
}

.cluster-end {
  --distribution: flex-end;
}
