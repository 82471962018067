:root {
  --font-serif: Grenette, Rockwell, "Rockwell Nova", "Roboto Slab",
    "DejaVu Serif", "Sitka Small", serif;
  --font-sans-serif: Satoshi, Inter, Roboto, "Helvetica Neue", "Arial Nova",
    "Nimbus Sans", Arial, sans-serif;

  /* Primary Brand Colors */

  --c-bright-white: hsl(0, 0%, 100%);
  --c-warm-white: hsl(45, 100%, 95%);
  --c-muted-cream: hsl(40, 100%, 90%);
  --c-soft-black: hsl(203, 26%, 17%);
  --c-hard-black: hsl(201, 38%, 9%);

  --c-shadow: hsla(203, 26%, 17%, 0.16);

  /* Secondary brand colors */

  --c-red: hsl(0, 100%, 66%);
  --c-red-dark: hsl(0, 59%, 47%);
  --c-red-light: hsl(0, 100%, 78%);
  --c-red-lightest: hsl(0, 100%, 87%);

  --c-orange: hsl(17, 100%, 63%);
  --c-orange-dark: hsl(17, 66%, 44%);
  --c-orange-light: hsl(17, 100%, 75%);
  --c-orange-lightest: hsl(17, 100%, 88%);

  --c-gold: hsl(34, 100%, 66%);
  --c-gold-dark: hsl(34, 78%, 35%);
  --c-gold-light: hsl(34, 100%, 78%);
  --c-gold-lightest: hsl(34, 100%, 88%);

  --c-green: hsl(150, 57%, 45%);
  --c-green-dark: hsl(150, 57%, 30%);
  --c-green-light: hsl(150, 46%, 64%);
  --c-green-lightest: hsl(150, 46%, 82%);

  --c-sapphire: hsl(192, 70%, 48%);
  --c-sapphire-dark: hsl(192, 70%, 33%);
  --c-sapphire-light: hsl(192, 65%, 66%);
  --c-sapphire-lightest: hsl(192, 65%, 83%);

  --c-blue: hsl(208, 98%, 41%);
  --c-blue-dark: hsl(208, 99%, 28%);
  --c-blue-light: hsl(208, 68%, 61%);
  --c-blue-lightest: hsl(208, 68%, 80%);

  /* Greys */

  --c-grey-cool: hsl(202, 11%, 41%);
  --c-grey-cool-dark: hsl(202, 11%, 34%);
  --c-grey-cool-light: hsl(202, 9%, 64%);
  --c-grey-cool-lightest: hsl(202, 9%, 86%);

  --c-grey-warm: hsl(50, 4%, 65%);
  --c-grey-warm-dark: hsl(50, 3%, 45%);
  --c-grey-warm-light: hsl(50, 10%, 76%);
  --c-grey-warm-lightest: hsl(50, 28%, 87%);

  --c-surface-warm: var(--c-warm-white);
  --c-surface-dark: var(--c-soft-black);
  --c-surface-light: var(--c-bright-white);

  --c-border-primary: var(--c-soft-black);
  --c-border-secondary: var(--c-grey-cool-dark);
  --c-border-invert: var(--c-warm-white);

  --s-5: 0.125rem;
  --s-4: 0.25rem;
  --s-3: 0.5rem;
  --s-2: 0.75rem;
  --s-1: 0.875rem;
  --s0: 1rem;
  --s1: 1.25rem;
  --s2: 1.5rem;
  --s3: 2rem;
  --s4: 3rem;
  --s5: 4rem;
  --s6: 6rem;
  --s7: 8rem;

  --measure: 72ch;
  --measure-medium: calc(var(--measure) * 1.25);
  --measure-large: calc(var(--measure) * 1.5);
  --measure-half: 36ch;
  --measure-small: 24ch;

  --illustration-size: 28rem;
  --headshot-size-small: 6.5rem;
  --headshot-size-large: 12rem;

  --transition-duration-extra-fast: 150ms;
  --transition-duration-fast: 300ms;
  --transition-duration-medium: 500ms;
  --transition-duration-slow: 1s;
  --transition-duration-extra-slow: 3s;

  --drop-shadow-light: 0px var(--s-5) var(--s-3) var(--c-shadow);
  --drop-shadow-medium: 0 var(--s-5) var(--s0) var(--c-shadow);
  --drop-shadow-heavy: 0 var(--s-5) var(--s3) var(--c-shadow);
}
