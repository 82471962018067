.industry-card {
  background-color: var(--c-bright-white);
  border-radius: var(--s-2);
  padding: var(--s1);
}
.industry-card.card {
    transition-property: box-shadow, scale, background-color;
  }
.industry-card a {
    color: var(--c-text);
  }
.industry-card figure {
    max-width: 60%;
    margin-left: 40%;
    color: var(--c-image);
    transition: color var(--transition-duration-extra-fast) ease-in-out;
  }
.industry-card:has(a)::after {
      background-image: url(/assets/images/icons/arrow-left.svg);
      height: 2.5rem;
      width: 2.5rem;
      left: var(--s1);
      bottom: var(--s1);
      right: auto;
    }
.industry-card:has(a):hover,
    .industry-card:has(a):focus-within {
      background-color: var(--c-active-background);
    }
.industry-card:has(a):hover figure, .industry-card:has(a):focus-within figure {
        color: var(--c-active-image);
      }
