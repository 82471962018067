.cta-banner {
  background-color: var(--c-background);
  color: var(--c-soft-black);
  padding: var(--s2);
  border-radius: var(--s0);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--s1);
  gap: var(--s1);
  text-align: center;
}

.cta-banner figure {
    color: var(--c-image);
    height: 8rem;
    width: 8rem;
    margin: 0 auto;
  }

.cta-banner:has(a)::after {
      display: none;
    }

@media (min-width: 42rem) {

  .cta-banner {
    text-align: left;
    grid-template-columns: 8rem 1fr;
    grid-template-rows: auto auto;
    align-items: center;
}

    .cta-banner .link {
      grid-column: 1 / 3;
      grid-row: 2;
    }
  }

@media (min-width: 66rem) {

  .cta-banner {
    grid-template-columns: 8rem 1fr auto;
    grid-template-rows: auto;
}

    .cta-banner .link {
      grid-column: 3;
      grid-row: 1;
    }
  }
