.stats {
  --c-stats: var(--c-red-dark);

  color: var(--c-red-dark);

  color: var(--c-stats);
  display: grid;
  font-size: max(1.5rem, min(8vw, 4rem));
  grid-gap: var(--s-1);
  gap: var(--s-1);
  grid-template-columns: repeat(auto-fit, minmax(3.5ch, 1fr));
  white-space: nowrap;
}
.stats .stat {
    font-family: var(--font-serif);
    font-weight: 300;
    line-height: 1;
  }
