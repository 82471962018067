.center,
.center-padded,
.center-intrinsic,
.center-text,
.center-narrow,
.center-medium,
.center-wide,
.center-xwide,
.center-self {
  --width: var(--measure);
  --content-width: 100%;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--measure);
  max-width: var(--width);
  width: 100%;
  width: var(--content-width);
}

.center-padded {
  padding-left: var(--s-2);
  padding-right: var(--s-2);
}

.center-intrinsic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-text {
  text-align: center;
}

.center-narrow {
  --width: calc(var(--measure) * 0.75);
}

.center-medium {
  --width: var(--measure-medium);
}

.center-wide {
  --width: var(--measure-large);
}

.center-xwide {
  --width: calc(var(--measure-large) * 1.25);
}

.center-self {
  --content-width: fit-content;
}
