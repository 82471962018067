.marquee {
  --gap: var(--s5);
  --space: var(--s5);

  padding: var(--s0) 0;
  margin: var(--s3) 0;
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  gap: var(--s5);
  gap: var(--gap);
}

@media (min-width: 42rem) {

.marquee {
    margin-top: var(--s5);
    margin-bottom: var(--s5);
}
  }

.marquee .marquee-content {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
  }

.marquee .marquee-content li {
      display: flex;
      align-items: center;
    }

.marquee .marquee-content .image {
      max-width: 12rem;
      max-height: 2.5rem;
      opacity: 0.66;
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      transition: all var(--transition-duration-extra-fast) ease-in-out;
    }

.marquee .marquee-content .image:hover,
      .marquee .marquee-content .image:focus {
        opacity: 1;
        -webkit-filter: grayscale(0);
                filter: grayscale(0);
      }

.marquee:not(.animate) {
    padding-left: var(--gap);
    padding-right: var(--gap);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

.marquee:not(.animate)::-webkit-scrollbar {
      display: none;
    }

.marquee.animate {
    overflow: hidden;
  }

.marquee.animate .marquee-content {
      -webkit-animation: scroll 40s linear infinite;
              animation: scroll 40s linear infinite;
    }

@-webkit-keyframes scroll {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scroll {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
            transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
  .marquee-content {
    -webkit-animation-play-state: paused !important;
            animation-play-state: paused !important;
  }
}
