.project-details {
  --space: var(--s2);
}
.project-details h1,
  .project-details h2,
  .project-details h3,
  .project-details .article-image {
    text-align: center;
  }
.project-details h1:not(:first-child), .project-details h2:not(:first-child), .project-details h3:not(:first-child), .project-details .article-image:not(:first-child) {
      margin-top: var(--s5) !important;
    }
.project-details blockquote {
    --space: var(--s4) !important;
  }
.project-details .article-image {
    margin-bottom: calc(var(--s5) - 0);
    margin-bottom: calc(var(--s5) - var(--space, 0));
  }
.project-details .article-image picture {
      aspect-ratio: var(--aspect-ratio);
      background-image: url(/assets/images/loaders/loader-light.gif);
      background-size: 80px 40px;
      background-repeat: no-repeat;
      background-position: center;
    }
.project-details .article-image picture.cropped {
        aspect-ratio: 1;
      }
@media (min-width: 42rem) {
  .project-details .article-image picture.cropped {
          aspect-ratio: 16 / 9;
      }
        }
.project-details > *:not(picture):not(img):not(.article-image):not(.statistics) {
    max-width: calc(var(--measure) * 0.75);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
