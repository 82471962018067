.project-card,
.project-card-featured {
  display: grid;
}

.project-card:has(a), .project-card-featured:has(a) {
    cursor: pointer;
  }

.project-card .text, .project-card-featured .text {
    grid-row: 2;
  }

.project-card .image, .project-card-featured .image {
    grid-row: 1;
    position: relative;
    overflow: hidden;
    border-radius: var(--s0);
    aspect-ratio: 1/1;
    background-image: url(/assets/images/loaders/loader-dark.gif);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position: center;
  }

.project-card .image .hover, .project-card-featured .image .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      scale: 1.1;
      transition: all var(--transition-duration-medium)
        cubic-bezier(0.25, 1, 0.5, 1);
    }

@media (hover: hover) {
      .project-card:hover .image, .project-card-featured:hover .image, .project-card:focus-within .image, .project-card-featured:focus-within .image {
        position: relative;
      }

        .project-card:hover .image .hover, .project-card-featured:hover .image .hover, .project-card:focus-within .image .hover, .project-card-featured:focus-within .image .hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          scale: 1.02;
        }
  }

.project-card {
  gap: var(--s0);
  align-content: start;
}

.project-card .text {
    padding: 0 var(--s1);
    position: relative;
  }

.project-card .text::before {
      content: "";
      background-color: var(--c-industry);
      display: block;
      height: 0.625rem;
      width: 0.625rem;
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: max(0.4rem, min(1.125vw, 0.5625rem));
    }

.project-card .text a {
      color: inherit;
    }

.project-card-featured {
  gap: var(--s2);
  padding: var(--s2) 0;
}

.project-card-featured .with-overline [class*="overline:"] {
    margin-bottom: 0;
  }

.project-card-featured .headline {
    font-size: max(1.75rem, min(4.75vw, 2.375rem));
  }

@media (min-width: 42rem) {

.project-card-featured {
    gap: var(--s4);
    padding: var(--s4) 0;
    align-items: center;
}

    .project-card-featured .text,
    .project-card-featured .image {
      grid-row: 1;
    }

    .project-card-featured:nth-of-type(odd) {
      grid-template-columns: 5fr 6fr;
    }

      .project-card-featured:nth-of-type(odd) .text {
        grid-column: 2;
      }

      .project-card-featured:nth-of-type(odd) .image {
        grid-column: 1;
      }

    .project-card-featured:nth-of-type(even) {
      grid-template-columns: 6fr 5fr;
    }

      .project-card-featured:nth-of-type(even) .text {
        grid-column: 1;
      }

      .project-card-featured:nth-of-type(even) .image {
        grid-column: 2;
      }
  }

@media (min-width: 66rem) {
      .project-card-featured:nth-of-type(odd) .text {
        padding-right: var(--s5);
      }
      .project-card-featured:nth-of-type(even) .text {
        padding-left: var(--s5);
      }
  }
