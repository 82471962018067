.statistics {
  --space: var(--s3) !important;
}
.statistics + .statistics {
    border-top: 2px solid var(--c-text-primary);
    padding-top: var(--space);
  }
.statistics .stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    justify-content: center;
    grid-gap: var(--s0);
  }
.statistics .stat {
    background-color: var(--c-surface-light);
    border-radius: var(--s0);
    color: var(--c-text-primary);
    padding: var(--s0) var(--s1);
    display: flex;
    justify-content: center;
  }
.statistics .stat .stat-value {
      display: flex;
      flex-direction: column;
    }
.statistics .stat .stat-value .stat-label {
        margin-bottom: var(--s-2);
        text-align: center;
      }
.statistics .stat .stat-value > :last-child {
        margin-top: auto;
      }
.statistics .stat .sup {
      font-size: 0.5em;
    }
.statistics .stat .percentage,
    .statistics .stat .direction {
      text-align: center;
    }
.statistics .stat .percentage:before, .statistics .stat .direction:before {
        content: "";
        display: inline-block;
        height: 0.7em;
        background-repeat: no-repeat;
        background-size: contain;
      }
.statistics .stat .percentage {
      font-size: max(2.375rem, min(8vw, 4rem));
    }
.statistics .stat .percentage:before {
        width: max(0.625rem, 0.18em);
        background-position: center;
      }
.statistics .stat .percentage-up:before {
        background-image: url(/assets/images/icons/stat-arrow-up.svg);
      }
.statistics .stat .percentage-down:before {
        background-image: url(/assets/images/icons/stat-arrow-down.svg);
      }
.statistics .stat .percentage-static:before {
        background-image: url(/assets/images/icons/stat-arrow-both.svg);
      }
.statistics .stat .direction {
      font-size: max(2rem, min(6.75vw, 3.375rem));
    }
.statistics .stat .direction:before {
        width: max(0.625rem, 0.25em);
        background-position: baseline;
      }
.statistics .stat .direction-up:before {
        background-image: url(/assets/images/icons/stat-up.svg);
      }
.statistics .stat .direction-down:before {
        background-image: url(/assets/images/icons/stat-down.svg);
      }
