[class*="surface"] {
  background-color: var(--c-surface);
}

.surface-warm {
  --c-surface: var(--c-surface-warm);
}

.surface-dark {
  --c-surface: var(--c-surface-dark);
}

.surface-light {
  --c-surface: var(--c-surface-light);
}
