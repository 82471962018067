
  .project-intro .cover {
    aspect-ratio: 1/1;
  }

@media (min-width: 42rem) {

.project-intro .cover {
      aspect-ratio: 5/3;
  }
    }

.project-intro .cover img {
      width: 100%;
      border-radius: var(--s0);
    }

.project-link {
  --c-link: var(--c-project-highlight, --c-red-dark);
  --c-hover: var(--c-project-highlight, --c-red-dark);
  --c-underline: var(--c-project-highlight, --c-red-dark);

  color: --c-red-dark;

  color: var(--c-link);
  font-weight: 700;
  flex-grow: 0;
}

.project-highlight .project-link {
    --c-link: var(--c-project-highlight-text);
    --c-hover: var(--c-project-highlight-text);
    --c-underline: var(--c-project-highlight-text);
  }
