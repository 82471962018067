.industries {
  --c-text-highlight: var(--c-text);

  display: grid;
  grid-gap: var(--s3);
  gap: var(--s3);
  position: relative;
}
.industries header p em {
    color: var(--c-text-highlight);

    transition: color var(--transition-duration-extra-fast) ease-in-out;
  }
.industries header p em::before {
      content: "";
      display: block;
      height: 0;
    }
@media (max-width: 41.99rem) {
    .industries header {
      text-align: center;
    }
  }
@media (min-width: 42rem) {
  .industries {
    grid-template-columns: 4fr 5fr;
    align-items: start;
    gap: var(--s5);
}

    .industries header {
      position: -webkit-sticky;
      position: sticky;
      top: var(--s3);
    }
  }
