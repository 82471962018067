.video-hero {
  --hero-max-size: 32rem;

  display: flex;
  justify-content: center;
  max-height: min(32rem, 90dvh);
  max-height: min(var(--hero-max-size), 90dvh);
  max-width: min(32rem, calc(100vw - var(--s0) * 2));
  max-width: min(var(--hero-max-size), calc(100vw - var(--s0) * 2));
  margin: 0 auto;
  aspect-ratio: 1/1;
}
.video-hero > * {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
.video-hero > img {
    opacity: 0;
    scale: 0.9;
    transition: all var(--transition-duration-medium)
      cubic-bezier(0.33, 1, 0.68, 1);
  }
.video-hero > img.visible {
      opacity: 1;
      scale: 1;
    }
