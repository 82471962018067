:root {
  --c-text-primary: var(--c-soft-black);
  --c-text-secondary: var(--c-grey-cool-dark);
  --c-text-placeholder: var(--c-grey-cool);
  --c-text-link: var(--c-blue);
  --c-text-link-hover: var(--c-blue-dark);
  --c-text-invert: var(--c-warm-white);
  --c-button-background: var(--c-soft-black);
  --c-button-text: var(--c-warm-white);
  --c-button-hover-background: var(--c-blue-dark);
  --c-button-hover-text: var(--c-warm-white);
  --c-heading-highlight: var(--c-red-light);
}

.text-on-dark {
  --c-text-primary: var(--c-warm-white);
  --c-text-secondary: var(--c-grey-warm-lightest);
  --c-text-placeholder: var(--c-grey-warm-light);
  --c-text-link: var(--c-blue-light);
  --c-text-link-hover: var(--c-blue-lightest);
  --c-text-invert: var(--c-soft-black);
  --c-button-background: var(--c-warm-white);
  --c-button-text: var(--c-soft-black);
  --c-button-hover-background: var(--c-blue-lightest);
  --c-button-hover-text: var(--c-soft-black);
  --c-heading-highlight: var(--c-red-dark);

  color: var(--c-warm-white);

  color: var(--c-text-primary);
}

html {
  /* font-size: calc(1em + 0.25vw); */
  font-size: 1em;
}

body {
  background-color: var(--c-surface-warm);
  color: var(--c-soft-black);
  color: var(--c-text-primary);
  font-family: var(--font-sans-serif);
  font-size: max(1rem, min(2.5vw, 1.125rem));
  line-height: 1.4;
  transition-property: color, background-color;
  transition-duration: var(--transition-duration-medium);
  transition-timing-function: ease-in-out;
}

body.project-highlight {
    background-color: --c-surface-warm;
    background-color: var(--c-project-highlight, --c-surface-warm);
    color: --c-text-primary;
    color: var(--c-project-highlight-text, --c-text-primary);
  }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-serif);
  font-weight: 300;
  line-height: 1.4;
  text-wrap: balance;
}

h1.banner, h2.banner, h3.banner, h4.banner, h5.banner, h6.banner, .h1.banner, .h2.banner, .h3.banner, .h4.banner, .h5.banner, .h6.banner {
    font-size: max(4.45rem, min(15.56vw, 7.78rem));
    line-height: 1.1;
  }

h1,
.h1 {
  font-size: max(2.375rem, min(8vw, 4rem));
  line-height: 1.2;
}

h2,
.h2 {
  font-size: max(2rem, min(6.75vw, 3.375rem));
  line-height: 1.2;
}

h3,
.h3 {
  font-size: max(1.625rem, min(5.5vw, 2.75rem));
  line-height: 1.3;
}

h4,
.h4 {
  font-size: max(1.25rem, min(4.75vw, 2.375rem));
}

h5,
.h5 {
  font-size: max(1.125rem, min(4vw, 2rem));
}

h6,
.h6 {
  font-size: max(1rem, min(3.25vw, 1.625rem));
}

a {
  --c-link: var(--c-text-link);
  --c-hover: var(--c-text-link-hover);
  --c-underline: var(--c-hover);

  color: var(--c-blue);

  color: var(--c-link);
  transition: all var(--transition-duration-extra-fast) ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
  a:focus,
  a:active,
  a.active {
    color: var(--c-hover);
  }

.text-link {
  --c-underline: var(--c-link);

  font-weight: 500;
}

.text-link span {
    background-image: linear-gradient(
      to right,
      var(--c-underline) 0%,
      var(--c-underline) 100%
    );
    background-repeat: no-repeat;
    background-size: 0 2px;
    background-position: 0 100%;
    transition: inherit;
    transition-property: background-size;
  }

.text-link:hover,
  .text-link:focus,
  .text-link:active,
  .text-link.active,
  .text-link[aria-expanded="true"] {
    color: var(--c-link);
  }

.text-link:hover span, .text-link:focus span, .text-link:active span, .text-link.active span, .text-link[aria-expanded="true"] span {
      background-size: 100% var(--s-5);
      outline: 0;
    }

/* Link sizes */

[class*="link:"] {
  font-family: var(--font-sans-serif);
  font-weight: 700;
  color: inherit;
}

[class*="link:"]:hover,
  [class*="link:"]:focus,
  [class*="link:"]:active,
  .active[class*="link:"] {
    color: inherit;
  }

[class*="link:"] .icon {
    width: 1em;
    height: 1em;
  }

.font-size\:xlarge,
.link\:xlarge {
  font-size: max(1.25rem, min(3.25vw, 1.625rem));
}

.font-size\:large,
.link\:large {
  font-size: max(1.125rem, min(2.5vw, 1.25rem));
}

.font-size\:default,
.link\:default {
  font-size: max(1rem, min(2.25vw, 1.125rem));
}

.font-size\:small,
.link\:small {
  font-size: max(0.875rem, min(2vw, 1rem));
}

.font-size\:xsmall,
.link\:xsmall {
  font-size: max(0.75rem, min(1.75vw, 0.875rem));
}

/* Quote */

.blockquote {
  font-style: italic;
}

.blockquote .quote > :first-child::before {
    content: "“";
  }

.blockquote .quote > :last-child::after {
    content: "”";
  }

/* Overlines */

[class*="overline:"] {
  font-family: var(--font-sans-serif);
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
}

.overline\:xlarge {
  font-size: max(1rem, min(3.25vw, 1.625rem));
}

.overline\:large {
  font-size: max(0.875rem, min(2.25vw, 1.125rem));
}

.overline\:small {
  font-size: max(0.75rem, min(1.75vw, 0.875rem));
}

/* Font size classes */

.font-size\:xlarge {
  font-size: max(1.25rem, min(3.25vw, 1.625rem));
}

.font-size\:large {
  font-size: max(1.125rem, min(2.5vw, 1.25rem));
}

.font-size\:default {
  font-size: max(1rem, min(2.25vw, 1.125rem));
}

.font-size\:small {
  font-size: max(0.875rem, min(2vw, 1rem));
}

.font-size\:xsmall {
  font-size: 0.75rem;
}

/* Font weight classes */

.font-weight\:bold {
  font-weight: 700;
}

.font-weight\:medium {
  font-weight: 500;
}

.font-weight\:normal {
  font-weight: 400;
}

.font-weight\:light {
  font-weight: 300;
}

/* Font style */

.font-style\:italic {
  font-style: italic;
}

/* Text Alignment */

.text-align\:left {
  text-align: left !important;
}

.text-align\:center {
  text-align: center !important;
}

.text-align\:right {
  text-align: right !important;
}

/* Lists */

.list-style\:none {
  padding: 0;
  list-style: none;
}

/* Typefaces */

.font-family\:sans-serif {
  font-family: var(--font-sans-serif);
}

.font-family\:serif {
  font-family: var(--font-serif);
}

/* Animatable italics */

@media (prefers-reduced-motion: no-preference) {
  em.animatable {
    background-image: linear-gradient(
      to right,
      var(--c-red-light) 0%,
      var(--c-red-light) 100%
    );
    background-image: linear-gradient(
      to right,
      var(--c-heading-highlight) 0%,
      var(--c-heading-highlight) 100%
    );
    background-repeat: no-repeat;
    background-size: 0 0.2em;
    background-position: 0 0.875em;
  }

    em.animatable.highlight {
      -webkit-animation: highlight-text 3s ease-in-out;
              animation: highlight-text 3s ease-in-out;
    }
}

/* Utilities */

figcaption,
.caption {
  text-align: center;
  font-style: italic;
  font-size: 0.875rem;
}

figure figcaption {
  margin-top: var(--s-3);
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.error.alert {
    color: var(--c-red-dark);
    font-weight: 400;
  }

/* Heading animation */

@-webkit-keyframes highlight-text {
  0% {
    background-size: 0 0.2em;
  }
  22% {
    background-size: 0 0.2em;
  }
  40% {
    background-size: 100% 0.2em;
  }
  80% {
    background-size: 100% 0.2em;
    background-position: 0 0.875em;
  }
  100% {
    background-size: 200% 0.2em;
    background-position: -100% 0.875em;
  }
}

@keyframes highlight-text {
  0% {
    background-size: 0 0.2em;
  }
  22% {
    background-size: 0 0.2em;
  }
  40% {
    background-size: 100% 0.2em;
  }
  80% {
    background-size: 100% 0.2em;
    background-position: 0 0.875em;
  }
  100% {
    background-size: 200% 0.2em;
    background-position: -100% 0.875em;
  }
}
