.box,
[class*="box-"] {
  --padding-x: var(--s0);
  --padding-y: var(--s0);

  padding: var(--s0) var(--s0);

  padding: var(--padding-y) var(--padding-x);
  border: 0 solid;
}

.box.full-width, .full-width[class*="box-"] {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

.box.full-width, .full-width[class*="box-"] {
    position: relative;
    left: 50%;
    width: 100vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 0;
  }

@media (min-width: 42rem) {

.box,
[class*="box-"] {
    --padding-x: 1.75rem;
}
  }

.box > .box,
  [class*="box-"] > .box,
  .box [class*="box-"],
  [class*="box-"] [class*="box-"] {
    --padding-x: 0;
  }

.box-tight {
  --padding-x: var(--s-2);
  --padding-y: var(--s-2);
}

.box-flush {
  --padding-x: 0;
  --padding-y: 0;
}

.box-px-0 {
  --padding-x: 0;
}

.box-px-0 > .box,
  .box-px-0 > [class*="box-"] {
    --padding-x: var(--s0);
  }

.box-py-0 {
  --padding-y: 0;
}

.box-comfy {
  --padding-y: var(--s1);
}

@media (min-width: 42rem) {

.box-comfy {
    --padding-y: var(--s3);
}
  }

.box-roomy {
  --padding-y: var(--s3);
}

@media (min-width: 42rem) {

.box-roomy {
    --padding-y: var(--s6);
}
  }

.box-roomy-bottom {
  padding-bottom: var(--s3);
}

@media (min-width: 42rem) {

.box-roomy-bottom {
    padding-bottom: var(--s6);
}
  }
