.toggler {
  border: 2px solid var(--c-button-background);
  border-radius: var(--s7);
  gap: 2px;
  display: inline-flex;
  padding: 0;
  list-style: none;
  font-family: var(--font-serif);
  -webkit-text-decoration: none;
  text-decoration: none;
}
.toggler .toggle-option {
    --c-link: var(--c-text-primary);
    --c-hover: var(--c-text-primary);

    color: inherit;
    display: inline-block;
    border: none;
    background-color: transparent;
    font-size: max(1rem, min(2.5vw, 1.25rem));
    padding: max(0.625rem, min(1.5vw, 0.75rem)) max(0.75rem, min(2vw, 1rem));
    border-radius: calc(var(--s7) - 2px);
    transition: all var(--transition-duration-extra-fast) ease-in-out;
    white-space: nowrap;
  }
.toggler .toggle-option.large {
      font-size: max(1.125rem, min(3.25vw, 1.625rem));
      padding: max(1rem, min(2.5vw, 1.25rem)) max(1.5rem, min(4vw, 2rem));
    }
.toggler .toggle-option.active {
      background-color: var(--c-button-background);
      color: var(--c-button-text);
      pointer-events: none;
      cursor: default;
    }
.toggler .toggle-option.active span {
        background-size: 0;
      }
