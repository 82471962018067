label {
  font-weight: 700;
  font-size: max(1rem, min(2.25vw, 1.125rem));
  padding: 0;
}

input {
  --color-border: var(--c-grey-warm-light);

  background: transparent;
  border: 2px solid transparent;
  border-bottom-color: var(--c-grey-warm-light);
  border-bottom-color: var(--color-border);
  border-top-right-radius: var(--s0);
  border-top-left-radius: var(--s0);
  padding: var(--s-2) var(--s0);
  transition: all var(--transition-duration-extra-fast) ease-in-out;
  outline: none;
}

input::-webkit-input-placeholder {
    color: transparent;
  }

input::placeholder {
    color: transparent;
  }

input:hover,
  input:focus {
    --color-border: var(--c-text-link);
  }

input:focus {
    border-radius: var(--s0);
    border-color: var(--color-border);
    background: var(--c-surface-light);
  }

textarea {
  --color-border: var(--c-grey-warm-light);

  background: transparent;
  border: 2px solid var(--c-grey-warm-light);
  border: 2px solid var(--color-border);
  padding: var(--s-2) var(--s0);
  border-radius: var(--s0);
  transition: all var(--transition-duration-extra-fast) ease-in-out;
  outline: none;
}

textarea::-webkit-input-placeholder {
    color: var(--c-grey-cool);
  }

textarea::placeholder {
    color: var(--c-grey-cool);
  }

textarea:hover,
  textarea:focus {
    --color-border: var(--c-text-link);
  }

textarea:focus {
    background: var(--c-surface-light);
  }

textarea:not([rows]) {
    min-height: 8rem;
  }

input.error,
  textarea.error,
  input:not(:placeholder-shown):not(:focus):invalid,
  textarea:not(:placeholder-shown):not(:focus):invalid,
  input:user-invalid,
  textarea:user-invalid {
    --color-border: var(--c-red-dark);
  }

.field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.field-wrapper > * {
    margin-top: 0;
    margin-bottom: 0;
  }

.field-wrapper:has(input) > label {
      margin-top: var(--s-3);
      order: 2;
    }

.field-wrapper:has(textarea) > label {
      margin-bottom: var(--s-3);
    }

.field-wrapper.honeypot {
    position: absolute;
    left: -999rem;
    top: -999rem;
  }
