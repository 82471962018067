/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2023 Colophon Foundry
 *
 * Licensed to Matthew Parmet, ZeroDegrees
 */

@font-face {
  font-family: "Grenette";
  src: url("/assets/fonts/grenette/grenette-variable-proportional-pro.woff2")
    format("woff2");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("/assets/fonts/satoshi/Satoshi-Variable.woff2") format("woff2");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("/assets/fonts/satoshi/Satoshi-VariableItalic.woff2") format("woff2");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
