.team-members {
  display: grid;
  grid-gap: var(--s-1);
  gap: var(--s-1);
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
@media (min-width: 40rem) {
  .team-members {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    gap: var(--s1);
}
  }
.team-members .member {
    padding: max(var(--s-2), min(2vw, var(--s1)));
  }
.team-members .member a {
      display: block;
      color: inherit;
      margin: calc(var(--s0) * -1);
      padding: var(--s1);
      border-radius: var(--s-3);
      -webkit-text-decoration: none;
      text-decoration: none;
      transition-duration: var(--transition-duration-fast);
      transition-timing-function: ease-in-out;
      transition-property: background-color, scale;
      outline: none;
      position: relative;
    }
.team-members .member a * {
        transition: inherit;
      }
.team-members .member a[href*="linkedin.com"]::after {
        content: "";
        background-image: url(/assets/images/icons/linkedin.svg);
        background-size: contain;
        display: block;
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        right: var(--s-3);
        bottom: var(--s-3);
        opacity: 0;
        transition-duration: var(--transition-duration-extra-fast);
      }
.team-members .member a:hover,
      .team-members .member a:focus {
        background-color: var(--c-surface-light);
      }
.team-members .member a:hover .image img, .team-members .member a:focus .image img {
          scale: 1.06;
        }
.team-members .member a:hover h3, .team-members .member a:focus h3 {
          color: var(--c-text-link-hover);
        }
.team-members .member a:hover::after, .team-members .member a:focus::after {
          opacity: 1;
        }
.team-members .member .image {
      display: block;
      height: max(var(--headshot-size-small), min(24vw, var(--headshot-size-large)));
      width: max(var(--headshot-size-small), min(24vw, var(--headshot-size-large)));
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    }
.team-members .member .image img {
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: 100%;
      }
