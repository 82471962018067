.card:has(a) {
  transition-duration: var(--transition-duration-extra-fast);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, scale;
  cursor: pointer;
  position: relative;
}
.card:has(a)::after {
    content: "";
    background-image: url(/assets/images/icons/external-link-blue.svg);
    background-size: contain;
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    position: absolute;
    right: var(--s-1);
    bottom: var(--s-1);
    opacity: 0;
    transition-duration: inherit;
  }
.card:has(a):hover,
  .card:has(a):focus-within {
    box-shadow: var(--drop-shadow-medium);
    scale: 1.02;
    outline: 0;
  }
.card:has(a):hover::after, .card:has(a):focus-within::after {
      opacity: 1;
    }
