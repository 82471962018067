.stack,
.stack-flush,
.stack-tight,
.stack-comfy,
.stack-roomy,
.stack-loose,
.stack-extra-loose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack > *, .stack-flush > *, .stack-tight > *, .stack-comfy > *, .stack-roomy > *, .stack-loose > *, .stack-extra-loose > * {
    margin-top: 0;
    margin-bottom: 0;
  }

.stack > *.block, .stack-flush > *.block, .stack-tight > *.block, .stack-comfy > *.block, .stack-roomy > *.block, .stack-loose > *.block, .stack-extra-loose > *.block {
      width: 100%;
      box-sizing: border-box;
    }

.stack > * + *:not(:empty:last-child),
  .stack-flush > * + *:not(:empty:last-child),
  .stack-tight > * + *:not(:empty:last-child),
  .stack-comfy > * + *:not(:empty:last-child),
  .stack-roomy > * + *:not(:empty:last-child),
  .stack-loose > * + *:not(:empty:last-child),
  .stack-extra-loose > * + *:not(:empty:last-child),
  .stack > * + input,
  .stack-flush > * + input,
  .stack-tight > * + input,
  .stack-comfy > * + input,
  .stack-roomy > * + input,
  .stack-loose > * + input,
  .stack-extra-loose > * + input,
  .stack > * + textarea,
  .stack-flush > * + textarea,
  .stack-tight > * + textarea,
  .stack-comfy > * + textarea,
  .stack-roomy > * + textarea,
  .stack-loose > * + textarea,
  .stack-extra-loose > * + textarea {
    --space: var(--s1);

    margin-top: var(--s1);

    margin-top: var(--space);
  }

.stack > [hidden]:first-child + *,
  .stack-flush > [hidden]:first-child + *,
  .stack-tight > [hidden]:first-child + *,
  .stack-comfy > [hidden]:first-child + *,
  .stack-roomy > [hidden]:first-child + *,
  .stack-loose > [hidden]:first-child + *,
  .stack-extra-loose > [hidden]:first-child + *,
  .stack > .sr-only + *,
  .stack-flush > .sr-only + *,
  .stack-tight > .sr-only + *,
  .stack-comfy > .sr-only + *,
  .stack-roomy > .sr-only + *,
  .stack-loose > .sr-only + *,
  .stack-extra-loose > .sr-only + * {
    margin-top: 0;
  }

.stack-flush > * + *:not(:empty:last-child),
  .stack-flush > * + input,
  .stack-flush > * + textarea {
    --space: var(--s-5);
  }

.stack-tight > * + *:not(:empty:last-child),
  .stack-tight > * + input,
  .stack-tight > * + textarea {
    --space: var(--s-3);
  }

.stack-comfy > * + *:not(:empty:last-child),
  .stack-comfy > * + input,
  .stack-comfy > * + textarea {
    --space: var(--s0);
  }

.stack-roomy > * + *:not(:empty:last-child),
  .stack-roomy > * + input,
  .stack-roomy > * + textarea {
    --space: var(--s1);
  }

@media (min-width: 42rem) {

.stack-roomy > * + *:not(:empty:last-child),
  .stack-roomy > * + input,
  .stack-roomy > * + textarea {
      --space: var(--s3);
  }
    }

.stack-loose > * + *:not(:empty:last-child),
  .stack-loose > * + input,
  .stack-loose > * + textarea {
    --space: var(--s3);
  }

@media (min-width: 42rem) {

.stack-loose > * + *:not(:empty:last-child),
  .stack-loose > * + input,
  .stack-loose > * + textarea {
      --space: var(--s5);
  }
    }

.stack-extra-loose > * + *:not(:empty:last-child),
  .stack-extra-loose > * + input,
  .stack-extra-loose > * + textarea {
    --space: var(--s4);
  }

@media (min-width: 42rem) {

.stack-extra-loose > * + *:not(:empty:last-child),
  .stack-extra-loose > * + input,
  .stack-extra-loose > * + textarea {
      --space: var(--s7);
  }
    }
