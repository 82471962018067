
    .type\:design[class*="service"] .tag {
      --c-border: var(--c-red);
      --c-text: var(--c-red-dark);
    }

.type\:design[class*="service"] .image {
      color: var(--c-red);
    }

.type\:content[class*="service"] .tag {
      --c-border: var(--c-orange);
      --c-text: var(--c-orange-dark);
    }

.type\:content[class*="service"] .image {
      color: var(--c-orange);
    }

.type\:research[class*="service"] .tag {
      --c-border: var(--c-green);
      --c-text: var(--c-green-dark);
    }

.type\:research[class*="service"] .image {
      color: var(--c-green);
    }

.type\:strategy[class*="service"] .tag {
      --c-border: var(--c-blue);
      --c-text: var(--c-blue-dark);
    }

.type\:strategy[class*="service"] .image {
      color: var(--c-blue);
    }

.type\:development[class*="service"] .tag {
      --c-border: var(--c-yellow);
      --c-text: var(--c-yellow-dark);
    }

.type\:development[class*="service"] .image {
      color: var(--c-yellow);
    }

.project-highlight [class*="service"] .tag {
      --c-background: color-mix(in srgb, var(--c-warm-white), transparent 8%);
    }

.project-highlight [class*="service"] .tag:focus, .project-highlight [class*="service"] .tag:hover {
        background-color: var(--c-warm-white);
      }

.service-details {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--s0);
  gap: var(--s0);
  color: var(--c-text-primary);
}

.service .service-details {
    display: none;
  }

.service-details .h6 {
    font-size: 1.25rem;
  }

.service-details .image {
    grid-column: 1;
    width: 4rem;
    height: 4rem;
  }

.service-details .image svg {
      width: 100%;
      height: 100%;
    }

.service-details .text {
    grid-column: 2;
  }
