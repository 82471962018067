.project-testimonial {
  --quote-size: 1.5em;

  background-color: var(--c-background, var(--c-surface));
  border-radius: var(--s0);
  color: var(--c-text, var(--c-text-primary));
  padding: var(--s2) var(--s4);
}
.project-testimonial h3 {
    position: relative;
  }
.project-testimonial h3::before {
      content: "";
      display: block;
      width: var(--quote-size);
      height: var(--quote-size);
      border-radius: var(--quote-size);
      font-size: var(--quote-size);
      line-height: 2;
      background-color: var(--c-text, var(--c-text-primary));
      text-align: center;
      margin-bottom: var(--s2);
    }
.project-testimonial h3::after {
      content: "“";
      color: var(--c-background, var(--c-surface));
      font-size: calc(var(--quote-size) * 2.5);
      position: absolute;
      top: -0.06em;
      left: 0.07em;
    }
@media (min-width: 42rem) {
  .project-testimonial {
    padding: var(--s4) var(--s6);
}
  }
.surface-dark .project-testimonial {
    border: 2px solid var(--c-text, var(--c-text-primary));
  }
