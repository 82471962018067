.site-header {
  --color-background: var(--c-warm-white);
  --color-text: var(--c-soft-black);
  --color-dot: var(--c-red);
  --logo-width: 6rem;

  background-color: transparent;
  position: relative;
  transition-property: color, background-color;
  transition-duration: var(--transition-duration-fast);
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 10;
}
.project-highlight .site-header {
    --color-background: var(--c-project-highlight, --c-surface-warm);
    --color-text: var(--c-project-highlight-text, --c-text-primary);
  }
.site-header[class*="box"] {
    --padding-x: var(--s0);
    --padding-y: var(--s0);
  }
.site-header .logo {
    width: var(--logo-width);
    color: var(--color-text);
    display: block;
    transition: inherit;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
.site-header .logo svg {
      display: block;
      width: 100%;
    }
.site-header .logo svg .degrees {
        color: var(--color-dot);
      }
.site-header .menu-toggler {
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
  }
.site-header .menu-toggler button {
      padding: 0;
      background-color: transparent;
      border: none;
      color: var(--color-text);
      transition: color var(--transition-duration-fast) ease-in-out;
    }
.site-header .menu-toggler button svg {
        height: 1.5rem;
        width: 1.5rem;
        transition: inherit;
      }
.site-header .menu-toggler button:hover,
      .site-header .menu-toggler button:active,
      .site-header .menu-toggler button.focus-visible {
        background: inherit;
        color: var(--color-text);
      }
.site-header .menu-toggler button[aria-expanded="false"] .open {
          display: block;
        }
.site-header .menu-toggler button[aria-expanded="false"] .close {
          display: none;
        }
.site-header .menu-toggler button[aria-expanded="true"] .open {
          display: none;
        }
.site-header .menu-toggler button[aria-expanded="true"] .close {
          display: block;
        }
.site-header .nav {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
  }
.site-header .nav a {
      color: var(--color-text);
      display: block;
      padding: var(--s-3);
    }
.site-header .nav a.text-link {
        --c-underline: var(--c-red);

        padding-left: calc(var(--s-3) - var(--s-4));
        padding-right: calc(var(--s-3) - var(--s-4));
      }
.site-header .nav a.text-link span {
          padding-left: var(--s-4);
          padding-right: var(--s-4);
        }
.site-header .nav a.text-link:active,
        .site-header .nav a.text-link.active {
          --c-underline: var(--c-red-lightest);
        }
.site-header .nav a.text-link:active span, .site-header .nav a.text-link.active span {
            background-size: 100% var(--s-3);
          }
@media (max-width: 65.99rem) {
    .site-header .menu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-background);
      padding: var(--s5) var(--s1) var(--s1);
      flex-direction: column;
      justify-content: flex-end;
      z-index: 0;
      display: none;
    }

    .site-header.-open {
      --color-background: var(--c-soft-black);
      --color-text: var(--c-warm-white);
      --color-dot: var(--c-red);
    }

      .site-header.-open .menu {
        display: block;
        -webkit-animation: menu-in var(--transition-duration-fast)
          cubic-bezier(0.25, 1, 0.5, 1);
                animation: menu-in var(--transition-duration-fast)
          cubic-bezier(0.25, 1, 0.5, 1);
      }
      .site-header .nav a.text-link {
        --c-underline: var(--c-red-dark);

        font-size: 1.25rem;
        padding-top: var(--s-2);
        padding-bottom: var(--s-2);
      }

        .site-header .nav a.text-link:active,
        .site-header .nav a.text-link.active {
          --c-underline: var(--c-red-dark);
        }
  }
@media (min-width: 66rem) {
.site-header {
    display: grid;
    grid-template-columns: 1fr var(--logo-width) 1fr;
    grid-gap: var(--s0);
    gap: var(--s0);
    margin-bottom: var(--s2);
}

    .site-header .menu-toggler {
      display: none;
    }

    .site-header .logo {
      grid-column: 2 / 3;
      grid-row: 1;
      display: flex;
      align-items: center;
    }

    .site-header .menu {
      grid-column: 1 / 4;
      grid-row: 1;
      display: grid;
      grid-template-columns: 1fr var(--logo-width) 1fr;
      grid-gap: var(--s0);
      gap: var(--s0);
    }

    .site-header .nav {
      text-align: left;
      display: flex;
      gap: var(--s0);
      padding: var(--s-6) 0;
      font-size: 0.875rem;
      font-weight: 500;
    }

      .site-header .nav.nav-primary {
        grid-column: 1 / 2;
      }

      .site-header .nav.nav-secondary {
        grid-column: 3 / 4;
        justify-content: flex-end;
      }
  }
@-webkit-keyframes menu-in {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
@keyframes menu-in {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
