.tag {
  --c-border: var(--c-soft-black);
  --c-text: var(--c-soft-black);
  --c-background: transparent;

  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  background-color: var(--c-background);
  border: 1px solid var(--c-soft-black);
  border: 1px solid var(--c-border);
  color: var(--c-soft-black);
  color: var(--c-text);
  border-radius: var(--s0);
  padding: var(--s-3) var(--s-2);
  line-height: 1;
  text-wrap: nowrap;
}
button.tag, a.tag {
    transition: background-color ease-in-out
      var(--transition-duration-extra-fast);
  }
button.tag:focus, a.tag:focus, button.tag:hover, a.tag:hover {
      color: var(--c-text);
      outline: none;
      background-color: color-mix(in srgb, var(--c-border), transparent 95%);
    }
button.tag.active, a.tag.active {
      color: var(--c-text);
      background-color: var(--c-background);
    }
