.industry-overview {
  display: grid;
  grid-gap: var(--s3);
  gap: var(--s3);
}

.industry-overview .images {
    display: none;
    position: relative;
    aspect-ratio: 1/1;
  }

.industry-overview .images .image {
      opacity: 0;
      transition-duration: var(--transition-duration-fast);
      transition-timing-function: ease-in-out;
      transition-property: opacity;
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain;
      -o-object-position: center;
         object-position: center;
      position: absolute;
    }

@media (min-width: 66rem) {

.industry-overview {
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--s3);
}
      .industry-overview .industry-list a {
        color: var(--c-grey-cool);
      }

        .industry-overview .industry-list a:before,
        .industry-overview .industry-list a svg {
          opacity: 0;
        }

        .industry-overview .industry-list a.active {
          color: var(--c-text-primary);
        }

          .industry-overview .industry-list a.active:before,
          .industry-overview .industry-list a.active svg {
            opacity: 1;
          }

          .industry-overview .industry-list a.active svg {
            -webkit-transform: translate(25%, -50%);
                    transform: translate(25%, -50%);
          }

    .industry-overview .text {
      padding-right: var(--s5);
    }

    .industry-overview .images {
      display: block;
      grid-column: 2;
    }
        .industry-overview .images .image.active {
          opacity: 1;
        }

    .industry-overview a {
      margin-left: 0;
    }
  }

.industry-list a {
    position: relative;
    color: var(--c-text-primary);
    transition-duration: var(--transition-duration-extra-fast);
    transition-timing-function: ease-in-out;
    transition-property: color;
    margin-left: 1em;
    outline: none;
  }

.industry-list a:hover,
    .industry-list a:focus,
    .industry-list a:active {
      color: var(--c-highlight) !important;
    }

.industry-list a:hover svg, .industry-list a:focus svg, .industry-list a:active svg {
        color: inherit;
      }

.industry-list a:before,
    .industry-list a svg {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      transition: inherit;
      transition-property: opacity;
    }

.industry-list a:before {
      background-color: var(--c-highlight);
      height: 0.25em;
      width: 0.25em;
      border-radius: 0.25em;
      left: -0.75em;
    }

.industry-list a svg {
      right: -1em;
      height: 0.875em;
      width: 0.875em;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      transition-property: opacity, -webkit-transform;
      transition-property: opacity, transform;
      transition-property: opacity, transform, -webkit-transform;
    }
