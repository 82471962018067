.cta-join {
  background-color: var(--c-muted-cream);
  border-radius: var(--s1);
  padding: var(--s1);
}
.cta-join::after {
    display: none !important;
  }
.cta-join svg {
    max-width: var(--s6);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
