.dropdown-menu {
  position: relative;
}

.dropdown-menu .menu-trigger {
    --c-underline: var(--color);

    all: inherit;
    transition: all var(--transition-duration-extra-fast) ease-in-out;
    color: var(--color);
    cursor: pointer;
    padding-top: var(--s0);
    padding-bottom: var(--s0);
    display: flex;
    gap: var(--s-2);
  }

.dropdown-menu .menu-trigger svg {
      width: 1.25em;
    }

.dropdown-menu .menu {
    position: absolute;
    background-color: var(--c-surface-light);
    padding: var(--s2) var(--s0);
    border-radius: var(--s0);
    box-shadow: var(--drop-shadow-medium);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

.dropdown-menu .menu:not([hidden]) {
      -webkit-animation: var(--transition-duration-fast) cubic-bezier(0.33, 1, 0.68, 1)
        fadeIn;
              animation: var(--transition-duration-fast) cubic-bezier(0.33, 1, 0.68, 1)
        fadeIn;
    }

.dropdown-menu .menu.exiting {
      -webkit-animation: var(--transition-duration-extra-fast)
        cubic-bezier(0.5, 0, 0.75, 0) fadeOut;
              animation: var(--transition-duration-extra-fast)
        cubic-bezier(0.5, 0, 0.75, 0) fadeOut;
    }

.dropdown-menu.industry-selector .menu {
      padding-right: var(--s4);
    }

.dropdown-menu.industry-selector .menu a svg {
          opacity: 0;
        }

.dropdown-menu.industry-selector .menu a:not([aria-current="page"])::before {
            display: none;
          }

@media (hover: hover) {
              .dropdown-menu.industry-selector .menu a:not([aria-current="page"]):hover svg, .dropdown-menu.industry-selector .menu a:not([aria-current="page"]):focus svg {
                opacity: 1;
              }
          }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    scale: 0.92;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    scale: 0.92;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    scale: 1;
  }

  to {
    opacity: 0;
    scale: 0.92;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    scale: 1;
  }

  to {
    opacity: 0;
    scale: 0.92;
  }
}
